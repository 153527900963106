<template>
  <div class="company">
    <el-breadcrumb class="breadCrumb" separator="/">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>操作员管理</el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>

    <el-form class="cardInfo mt20" ref="form" :model="form">
      <el-header class="titleOne">基础信息</el-header>
      <el-row>
        <el-form-item class="imgs" label="营业执照：" prop="fontBusinessUrl">
          <div class="img">
            <img width="100%" height="100%" :src="form.fontBusinessUrl" alt="">
          </div>
          <p class="tips ml76">（请上传清晰的证照，系统会自动识别照片内信息，如信息有误可手动修改。）</p>
        </el-form-item>

        <el-form-item label="企业名称：" prop="companyName">
          <el-input v-model="form.companyName" class="input450" placeholder="请输入企业名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="统一信用代码证号：" prop="socCode">
          <el-input v-model="form.socCode" class="input450" placeholder="请输入统一信用代码证号" disabled></el-input>
        </el-form-item>
        <el-form-item label="办公地址：" class="adress">
          <el-select v-model="form.workProvinceName" placeholder="请选择" disabled>
            <el-option label="区域一" value="shanghai"></el-option>
          </el-select>
          <el-select v-model="form.workCityName" placeholder="请选择" disabled>
            <el-option label="区域一" value="shanghai"></el-option>
          </el-select>
          <el-select v-model="form.workDistrictName" placeholder="请选择" disabled>
            <el-option label="区域一" value="shanghai"></el-option>
          </el-select>
          <el-input v-model="form.workAddress" class="input394" placeholder="请输入详细地址" disabled></el-input>
        </el-form-item>
        <el-form-item label="核心企业名称：" prop="coreCompanyName">
          <el-input v-model="form.coreCompanyName" class="input450" placeholder="请输入核心企业名称" disabled></el-input>
          <p class="tips ml76" style="line-height: 40px">（请输入正确的核心企业名称以便进行企业绑定。）</p>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";

  export default {
    name: "Detail",
    components: {
      'DelayButton': DelayButton
    },
    data() {
      return {
        form: {},//数据
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //获取详情
      async getList() {
        const {data: res} = await this.$auth.get('/cpiaoju-auth/lxgw/front/company/getCoreDetail', {
         params:{
           id: this.$route.query.id
         }
        })
        if (res.code == 200) {
          this.form = res.data;
        } else {
          this.$message.error(res.msg);
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .company {
    /deep/ .avatar-uploader .el-upload {
      position: relative;
      width: 150px;
      height: 150px;
      border: 1px solid #D8D8D8;
      text-align: center;
    }

    .cardInfo {
      .el-row {
        padding: 20px 20px 0;
        box-sizing: border-box;
        background: #ffffff;
      }

      .ml76 {
        margin-left: 132px;
      }
      .mr30{
        margin-right: 30px;
      }
    }

    .upImg {
      display: inline-block;

      .icon {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;

        em {
          width: 36px;
          height: 36px;
          background: url("~@/assets/img/auth/tianjia_icon.png") no-repeat;
        }

        i {
          color: #999999;
          letter-spacing: 1px;
        }

      }

    }

    .upImgTip {
      margin-left: 12px;
      color: #C70009;
    }

    p.tips {
      color: #999999;
      line-height: 20px;
    }

    /deep/ .el-form-item__label {
      width: 140px;
      text-align: right;
    }

    .el-input.input394 {
      width: 394px;
    }

    .adress {
      /deep/ .el-select {
        width: 143px;
        margin-right: 10px;

        .el-input {
          width: 100%;
        }
      }

      /deep/ .el-input {
        width: 200px;
      }
    }

    .el-form-item.inlineBlock {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 0;

      /deep/ .el-form-item__content {
        display: inline-block;
      }
    }

    .ml76 {
      margin-left: 76px;
    }

    .upFileTip {
      color: #999999;
      margin-left: 10px;
    }

    /*按钮*/

    .upFileBtn {
      background: #ffffff;
      color: #C70009;
      border-color: #C70009;
      font-size: 14px;

      i {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("~@/assets/img/auth/sc_02.png") no-repeat center;
        margin-right: 4px;
      }
    }

    .upFileBtn:hover {
      background: #C70009;
      border-color: #C70009;
      color: #ffffff;

      i {
        background: url("~@/assets/img/auth/sc_01.png") no-repeat center;
      }
    }
    .imgs{
      .img{
        display: inline-block;
        margin-right: 20px;
        width: 150px;
        height: 150px;

        border: 1px solid #D8D8D8;
      }
    }
  }

</style>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    /*border-color: #409EFF;*/
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

</style>